import { defaultTheme } from 'react-admin'
import { deepPurple, red } from '@mui/material/colors'

const theme = {
  ...defaultTheme,
  palette: {
    primary: deepPurple,
    secondary: deepPurple,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    ...defaultTheme.components,
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& th.RaDatagrid-headerCell': {
            backgroundColor: '#eee',
            borderBottom: '2px solid #bbb',
            fontWeight: 'bold'
          },
          '& td.RaDatagrid-rowCell': {
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }
      }
    },
    RaSimpleFormIterator: {
      styleOverrides: {
        root: {
          '& li.RaSimpleFormIterator-line': { alignItems: 'center' },
          '& div.RaSimpleFormIterator-indexContainer': { paddingTop: 0 },
          '& span.RaSimpleFormIterator-action': { paddingTop: 0 },
          '& section.RaSimpleFormIterator-form': { display: 'flex', flexDirection: 'row', maxWidth: '1200px' },
          '& section.RaSimpleFormIterator-form > div': { flex: 1, marginRight: '1em' },
          '& section.RaSimpleFormIterator-form > div:last-child': { marginRight: '0.5em' }
        }
      }
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          '& .RaFileInput-dropZone': { backgroundColor: '#eee', maxWidth: '500px', transition: 'background-color 200ms' },
          '& .RaFileInput-dropZone:hover': { backgroundColor: '#ddd' }
        }
      }
    }
  }
}

export default theme
