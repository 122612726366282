import * as React from 'react'
import { RoleDefaults } from './defaults'
import { Typography } from '@mui/material'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

// List
// ====
export const RoleList = () => {
  return (
    <List sort={{ field: 'name', order: 'ASC' }} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => (
  <SimpleForm warnWhenUnsavedChanges defaultValues={RoleDefaults}>
    <Typography variant="h6" mt="0.5em" gutterBottom>Role</Typography>
    <Typography variant="subtitle2" gutterBottom>
      Working role of a member
    </Typography>
    <TextInput source="name" validate={required()} />
  </SimpleForm>
)

// Edit
// ====
export const RoleEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const RoleCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
