import * as React from 'react'
import { Resource } from 'react-admin'
import { UserList, UserEdit, UserCreate } from './users'
import { TeamList, TeamEdit, TeamCreate } from './teams'
import { RoleList, RoleEdit, RoleCreate } from './roles'
import { DietList, DietEdit, DietCreate } from './diets'
import { SceneList, SceneEdit, SceneCreate } from './scenes'
import { CompanyList, CompanyEdit, CompanyCreate } from './companies'
import { ProjectList, ProjectEdit, ProjectCreate } from './projects'
import { LocationList, LocationEdit, LocationCreate } from './locations'
import { CallsheetList, CallsheetEdit, CallsheetCreate } from './callsheets'
import { DocumentList, DocumentEdit, DocumentCreate } from './documents'

import UserIcon from '@mui/icons-material/Person'
import TeamIcon from '@mui/icons-material/Group'
import SceneIcon from '@mui/icons-material/Collections'
import ProjectIcon from '@mui/icons-material/Book'
import LocationIcon from '@mui/icons-material/Explore'
import CompaniesIcon from '@mui/icons-material/Business'
import CallsheetIcon from '@mui/icons-material/Description'
import DietIcon from '@mui/icons-material/RestaurantMenu'
import RoleIcon from '@mui/icons-material/LockOpen'
import DocumentIcon from '@mui/icons-material/Folder'

export function getResources(permissions = []) {
  return permissions.includes('superuser')
    ? getSuperUserResources()
    : getAdminResources()
}

export function getSuperUserResources() {
  return [
    <Resource name="users" icon={UserIcon} list={UserList} edit={UserEdit} create={UserCreate} />,
    <Resource name="roles" icon={RoleIcon} list={RoleList} edit={RoleEdit} create={RoleCreate} />,
    <Resource name="diets" icon={DietIcon} list={DietList} edit={DietEdit} create={DietCreate} />,
    <Resource name="companies" icon={CompaniesIcon} list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />,
    <Resource name="projects" icon={ProjectIcon} list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />,
    <Resource name="callsheets" icon={CallsheetIcon} list={CallsheetList} edit={CallsheetEdit} create={CallsheetCreate} />,
    <Resource name="scenes" icon={SceneIcon} list={SceneList} edit={SceneEdit} create={SceneCreate} />,
    <Resource name="locations" icon={LocationIcon} list={LocationList} edit={LocationEdit} create={LocationCreate} />,
    <Resource name="teams" icon={TeamIcon} list={TeamList} edit={TeamEdit} create={TeamCreate} />,
    <Resource name="documents" icon={DocumentIcon} list={DocumentList} edit={DocumentEdit} create={DocumentCreate} />,
  ]
}

export function getAdminResources() {
  return [
    <Resource name="projects" icon={ProjectIcon} list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />,
    <Resource name="callsheets" edit={CallsheetEdit} create={CallsheetCreate} />,
    <Resource name="scenes" edit={SceneEdit} create={SceneCreate} />,
    <Resource name="locations" icon={LocationIcon} list={LocationList} edit={LocationEdit} create={LocationCreate} />,
    <Resource name="teams" icon={TeamIcon} list={TeamList} edit={TeamEdit} create={TeamCreate} />,
    <Resource name="users" />,
    <Resource name="companies" />,
    <Resource name="roles" />,
    <Resource name="diets" />,
    <Resource name="documents" />,
  ]
}
