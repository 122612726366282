import * as React from 'react'
import { useGetIdentity, usePermissions } from 'react-admin'
import { TeamDefaults } from './defaults'
import { Typography } from '@mui/material'
import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  Loading,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from 'react-admin'

// List
// ====
export const TeamList = () => {
  const { permissions } = usePermissions()
  const { identity, isLoading } = useGetIdentity()

  if (isLoading) {
    return <Loading />
  }

  const filter = permissions && permissions.includes('superuser')
    ? null
    : { user_id: identity.id }

  return (
    <List sort={{ field: 'name', order: 'ASC' }} filter={filter} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="description" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { identity, isLoading } = useGetIdentity()

  if (isLoading) {
    return <Loading />
  }

  return (
    <SimpleForm warnWhenUnsavedChanges defaultValues={TeamDefaults}>
      <Typography variant="h6" mt="0.5em" gutterBottom>
        Team
      </Typography>
      <Typography variant="subtitle2">
        General team information and members
      </Typography>

      <TextInput sx={{ display: 'none' }} disabled defaultValue={identity.id} source="user_id" />

      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />

      <Typography variant="h6" mt="0.5em" gutterBottom>
        Members
      </Typography>
      <Typography variant="subtitle2">
        Manage what members will be imported from this team
      </Typography>

      <ArrayInput sx={{ maxWidth: '800px' }} source="members">
        <SimpleFormIterator>
          <ReferenceInput label="Member" source="user_id" reference="users">
            <SelectInput defaultValue={null} emptyText="No member" helperText="" optionText={(r) => `${r.first_name} ${r.last_name}`} />
          </ReferenceInput>

          <ReferenceInput label="Role" source="role_id" reference="roles">
            <SelectInput defaultValue={null} emptyText="No role" helperText="" optionText="name" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm >
  )
}

// Edit
// ====
export const TeamEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const TeamCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
