import { today, timeOfDay, projectStatuses, weatherTypes } from './utils'

export const UserDefaults = {
  id: 0,
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  company_id: null,
  description: '',
  // ints
  diet_ids: [],
  // ints
  role_ids: [],
  // strings
  permissions: [],
}

export const RoleDefaults = {
  id: 0,
  name: '',
}

export const DietDefaults = {
  id: 0,
  name: '',
}

export const CompanyDefaults = {
  id: 0,
  admin_id: null,
  name: '',
  email: '',
  address: '',
  description: '',
  invoice_email: '',
  org_nr: '',
}

export const TeamDefaults = {
  id: 0,
  user_id: null,
  name: '',
  description: '',
  // { user_id, role_id }
  members: [],
}

export const LocationDefaults = {
  id: 0,
  user_id: 0,
  name: '',
  description: '',
  address: '',
  url: '',
}

export const SceneDefaults = {
  id: 0,
  project_id: null,
  location_id: null,
  name: '',
  description: '',
  time_of_day: timeOfDay[0].id,
  // { user_id, description }
  actors: [],
  // { name, email, description, phone, highlight, role_id }
  custom_actors: [],
}

export const DocumentDefaults = () => ({
  id: 0,
  project_id: null,
  name: '',
  file: null,
  created_at: new Date(),
})

export const CallsheetDefaults = () => ({
  id: 0,
  project_id: null,
  name: '',
  date: today(),
  call_time: '08:00',
  weather_degrees: 21,
  weather_type: weatherTypes[0].id,
  notes: '',
  // { type, duration, name, scene_id, location_id, notes }
  schedule: [],
  // { user_id, role_id, call_time, highlight }
  members: [],
  // { name, email, phone, role_id, call_time, highlight }
  custom_members: [],
})

export const ProjectDefaults = () => ({
  id: 0,
  user_id: null,
  // ints
  admin_ids: [],
  status: projectStatuses[0].id,
  start_date: today(),
  end_date: today(),
  description: '',
  name: '',
  production_company: '',
  client: '',
  notes: '',
  // { user_id, role_id, highlight }
  members: [],
  // { name, email, phone, role_id, highlight }
  custom_members: [],
})
