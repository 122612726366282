var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { createElement } from 'react';
import { MenuList } from '@mui/material';
import { styled } from '@mui/material/styles';
import DefaultIcon from '@mui/icons-material/ViewList';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import clsx from 'clsx';
import { useResourceDefinitions, useGetResourceLabel, useCreatePath, } from 'ra-core';
import { DRAWER_WIDTH, CLOSED_DRAWER_WIDTH } from './Sidebar';
import { useSidebarState } from './useSidebarState';
import { DashboardMenuItem } from './DashboardMenuItem';
import { MenuItemLink } from './MenuItemLink';
/**
 * Renders a menu with one menu item per resource by default. You can also set menu items by hand.
 *
 * @example
 * import * as React from 'react';
 * import { Menu } from 'react-admin';
 *
 * import BookIcon from '@mui/icons-material/Book';
 * import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
 * import PeopleIcon from '@mui/icons-material/People';
 * import LabelIcon from '@mui/icons-material/Label';
 *
 * export const MyMenu = () => (
 *     <Menu>
 *         <Menu.DashboardItem />
 *         <Menu.Item to="/posts" primaryText="Posts" leftIcon={<BookIcon />}/>
 *         <Menu.Item to="/comments" primaryText="Comments" leftIcon={<ChatBubbleIcon />}/>
 *         <Menu.Item to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
 *         <Menu.Item to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />}/>
 *     </Menu>
 * );
 */
export var Menu = function (props) {
    var _a;
    var resources = useResourceDefinitions();
    var getResourceLabel = useGetResourceLabel();
    var createPath = useCreatePath();
    var hasDashboard = props.hasDashboard, _b = props.children, children = _b === void 0 ? __spreadArray([
        hasDashboard ? (React.createElement(DashboardMenuItem, { key: "default-dashboard-menu-item" })) : null
    ], Object.keys(resources)
        .filter(function (name) { return resources[name].hasList; })
        .map(function (name) { return (React.createElement(MenuItemLink, { key: name, to: createPath({
            resource: name,
            type: 'list',
        }), state: { _scrollToTop: true }, primaryText: getResourceLabel(name, 2), leftIcon: resources[name].icon ? (createElement(resources[name].icon)) : (React.createElement(DefaultIcon, null)) })); }), true) : _b, className = props.className, rest = __rest(props, ["hasDashboard", "children", "className"]);
    var open = useSidebarState()[0];
    return (React.createElement(Root, __assign({ className: clsx((_a = {},
            _a[MenuClasses.open] = open,
            _a[MenuClasses.closed] = !open,
            _a), className) }, rest), children));
};
Menu.propTypes = {
    className: PropTypes.string,
    dense: PropTypes.bool,
    hasDashboard: PropTypes.bool,
};
// re-export MenuItem commponents for convenience
Menu.Item = MenuItemLink;
Menu.DashboardItem = DashboardMenuItem;
var PREFIX = 'RaMenu';
export var MenuClasses = {
    open: "".concat(PREFIX, "-open"),
    closed: "".concat(PREFIX, "-closed"),
};
var Root = styled(MenuList, {
    name: PREFIX,
    overridesResolver: function (props, styles) { return styles.root; },
})(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '0.5em',
            marginBottom: '1em'
        },
        _b[theme.breakpoints.only('xs')] = {
            marginTop: 0,
        },
        _b.transition = theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        _b["&.".concat(MenuClasses.open)] = {
            width: lodashGet(theme, 'sidebar.width', DRAWER_WIDTH),
        },
        _b["&.".concat(MenuClasses.closed)] = {
            width: lodashGet(theme, 'sidebar.closedWidth', CLOSED_DRAWER_WIDTH),
        },
        _b);
});
