import * as React from 'react'
import { useGetIdentity, usePermissions } from 'react-admin'
import { LocationDefaults } from './defaults'
import { Typography } from '@mui/material'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  Loading,
  required,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin'

// List
// ====
export const LocationList = () => {
  const { permissions, isLoading: permissionsLoading } = usePermissions()
  const { identity, isLoading: identityLoading } = useGetIdentity()

  if (permissionsLoading || identityLoading) {
    return <Loading />
  }

  const filter = permissions && permissions.includes('superuser')
    ? null
    : { user_id: identity.id }

  return (
    <List sort={{ field: 'name', order: 'ASC' }} filter={filter} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="adress" />
        <UrlField source="url" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { identity, isLoading: identityLoading } = useGetIdentity()

  if (identityLoading) {
    return <Loading />
  }

  return (
    <SimpleForm warnWhenUnsavedChanges defaultValues={LocationDefaults}>
      <Typography variant="h5" mt="0.5em" gutterBottom>
        Location
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        General information
      </Typography>

      <TextInput sx={{ display: 'none' }} disabled defaultValue={identity.id} source="user_id" />

      <TextInput source="name" validate={required()} />
      <TextInput source="description" validate={required()} />
      <TextInput label="Address (optional)" source="adress" />
      <TextInput label="Google Maps URL (optional)" source="url" />
    </SimpleForm>
  )
}

// Edit
// ====
export const LocationEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const LocationCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
