import jsonServerProvider from 'ra-data-json-server'
import { API_URL } from './utils'

async function uploadDocument(file) {
  const formData = new FormData()
  formData.append('document', file)
  const request = { method: "POST", body: formData }
  const response = await fetch(`${API_URL}/upload`, request)
  const data = await response.json()
  return data.src || null
}

const provider = jsonServerProvider(API_URL)

export default {
  ...provider,

  create: async (resource, params) => {
    if (resource !== 'documents') {
      return provider.create(resource, params)
    }

    const { rawFile, title } = params.data.file
    const src = await uploadDocument(rawFile)

    const result = await provider.create(resource, {
      data: { ...params.data, file: { title, src } }
    })

    return Promise.resolve(result)
  },

  update: async (resource, params) => {
    if (resource !== 'documents') {
      return provider.update(resource, params)
    }

    // File wasnt edited
    if (!(params.data.file.rawFile instanceof File)) {
      return provider.update(resource, params)
    }

    const { rawFile, title } = params.data.file
    const src = await uploadDocument(rawFile)

    const result = await provider.create(resource, {
      data: { ...params.data, file: { title, src } }
    })

    return Promise.resolve(result)
  }
}
