import * as React from 'react'
import { DocumentDefaults } from './defaults'
import { Typography, Box } from '@mui/material'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {
  Button,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FileInput,
  FileField,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'

// List
// ====
export const DocumentList = () => {
  return (
    <List filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />

        <ReferenceField source="project_id" reference="projects">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="file.title" />
        <DateField source="created_at" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { permissions } = usePermissions()
  const record = useRecordContext()
  const isAdmin = permissions && permissions.includes('superuser')

  const toolbar = (!record || !record.project_id) ? undefined : (
    <Toolbar>
      <Box flex={1}>
        <SaveButton />
        <Button
          color="info"
          sx={{ marginLeft: '1em' }}
          label="Back to Project"
          startIcon={<ArrowBack />}
          href={`#/projects/${record.project_id}/4`} />
      </Box>
      <Box>
        <DeleteButton />
      </Box>
    </Toolbar>
  )

  return (
    <SimpleForm toolbar={toolbar} warnWhenUnsavedChanges defaultValues={DocumentDefaults}>
      <Typography variant="h6" mt="0.5em" gutterBottom>
        Document
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Enter the displayed document name and upload a PDF
      </Typography>

      <TextInput source="name" validate={required()} />

      <ReferenceInput source="project_id" reference="projects">
        <SelectInput emptyText="No project" validate={required()} disabled={!isAdmin} optionText="name" />
      </ReferenceInput>

      <FileInput source="file" label="Choose a PDF to upload" accept="application/pdf" validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  )
}

// Edit
// ====
export const DocumentEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const DocumentCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
