import * as React from 'react'
import { UserDefaults } from "./defaults"
import { Typography } from '@mui/material'
import { RichTextInput, RichTextInputToolbar, LevelSelect, FormatButtons } from 'ra-input-rich-text'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  List,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

// List
// ====
export const UserList = () => {
  return (
    <List sort={{ field: 'last_name', order: 'ASC' }} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="first_name" />
        <TextField source="last_name" />

        <ReferenceField source="company_id" reference="companies">
          <TextField source="name" />
        </ReferenceField>

        <EmailField source="email" />
        <TextField source="phone" />

        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  return (
    <SimpleForm warnWhenUnsavedChanges defaultValues={UserDefaults}>
      {/* LEFT */}
      <Typography variant="h6" gutterBottom>
        User
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        General information displayed on the profile page
      </Typography>

      <TextInput label="First name" source="first_name" validate={required()} />
      <TextInput label="Last name" source="last_name" validate={required()} />
      <TextInput label="Phone" source="phone" validate={required()} />
      <TextInput label="Email" source="email" validate={required()} />

      <ReferenceInput source="company_id" reference="companies">
        <SelectInput emptyText="No company" optionText="name" />
      </ReferenceInput>

      <RichTextInput
        source="description"
        toolbar={<RichTextInputToolbar><LevelSelect /><FormatButtons /></RichTextInputToolbar>} />

      {/* RIGHT */}
      <Typography variant="h6" mt="0.5em">
        Diet
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Select one or more diet(s) and/or allergies
      </Typography>

      <ReferenceArrayInput validate={required()} source="diet_ids" reference="diets">
        <SelectArrayInput>
          <TextField source="name" />
        </SelectArrayInput>
      </ReferenceArrayInput>

      <Typography variant="h6" mt="0.5em">
        Roles
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Select one or more roles
      </Typography>

      <ReferenceArrayInput validate={required()} source="role_ids" reference="roles">
        <SelectArrayInput>
          <TextField source="name" />
        </SelectArrayInput>
      </ReferenceArrayInput>

      <Typography variant="h6" mt="0.5em">
        Permissions
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Select zero or more roles
      </Typography>

      <SelectArrayInput
        label="Permissions"
        source="permissions"
        choices={[
          { id: 'superuser', name: 'Superuser' },
          { id: 'admin', name: 'Admin' },
        ]} />
    </SimpleForm >
  )
}

// Edit
// ====
export const UserEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const UserCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
