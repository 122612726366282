export const API_URL = process.env.API_URL || 'http://localhost:3000'

export const richTextInputOpts = [
  { 'header': [1, 2, 3, false] },
  'bold',
  'italic',
  'underline'
]

export const timeOfDay = [
  { id: 'day', name: 'Day' },
  { id: 'night', name: 'Night' },
]

export const scheduleTypes = [
  { id: 'rig', name: 'Rig' },
  { id: 'shoot', name: 'Shoot' },
  { id: 'travel', name: 'Travel' },
  { id: 'lunch', name: 'Lunch' },
  { id: 'wrap', name: 'Wrap' },
  { id: 'other', name: 'Other' },
]

export const weatherTypes = [
  { id: 'sunny', name: 'Sunny' },
  { id: 'partly-sunny', name: 'Partly sunny' },
  { id: 'rainy', name: 'Rainy' },
  { id: 'cloudy', name: 'Cloudy' },
  { id: 'cloudy-night', name: 'Cloudy night' },
  { id: 'moon', name: 'Night' },
  { id: 'thunderstorm', name: 'Stormy' },
]

export const projectStatuses = [
  { id: 'draft', name: 'Draft' },
  { id: 'published', name: 'Published' },
  { id: 'archived', name: 'Archived' },
]

export const memberHighlight = [
  { id: false, name: 'Normal' },
  { id: true, name: 'Highlighted' },
]

export const permissions = [
  'superuser',
  'admin',
]

const pad = (n) => n < 10 ? `0${n}` : n

export function today() {
  const date = new Date()
  const yy = date.getFullYear()
  const mm = pad(date.getMonth() + 1)
  const dd = pad(date.getDate())
  return `${yy}-${mm}-${dd}`
}

export const scheduleInputStyles = {
  '& li.RaSimpleFormIterator-line': { padding: '8px 16px' },
  '& li.RaSimpleFormIterator-line:nth-child(even)': { backgroundColor: '#f9f9f9' },
  '& p.RaSimpleFormIterator-index': { fontSize: '21px', fontWeight: 'bold' },
}
