import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { Admin } from 'react-admin'
import authProvider from './auth-provider'
import dataProvider from './data-provider'
import theme from './theme'
import { getResources } from './resources'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Admin
    title="Callsheet Admin"
    theme={theme}
    authProvider={authProvider}
    dataProvider={dataProvider}
    requireAuth
    disableTelemetry>
    {getResources}
  </Admin>
)
