import * as React from 'react'
import { CompanyDefaults } from './defaults'
import { Typography } from '@mui/material'
import { RichTextInput, RichTextInputToolbar, LevelSelect, FormatButtons } from 'ra-input-rich-text'
import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  EmailField,
  List,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  usePermissions,
} from 'react-admin'

// List
// ====
export const CompanyList = () => {
  return (
    <List sort={{ field: 'name', order: 'ASC' }} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <EmailField source="email" />
        <TextField source="invoice_email" />
        <TextField source="address" />
        <TextField source="org_nr" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { permissions } = usePermissions()
  const isAdmin = permissions && permissions.includes('superuser')

  return (
    <SimpleForm warnWhenUnsavedChanges defaultValues={CompanyDefaults}>
      <Typography variant="h6" mt="0.5em" gutterBottom>
        Company
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        General company information
      </Typography>

      <TextInput source="name" validate={required()} />

      <ReferenceInput source="admin_id" reference="users">
        <SelectInput emptyText="No admin" disabled={!isAdmin} optionText="first_name" />
      </ReferenceInput>

      <TextInput source="email" validate={required()} />
      <TextInput source="invoice_email" validate={required()} />

      <TextInput label="Org. number" source="org_nr" validate={required()} />
      <TextInput source="address" validate={required()} />

      <RichTextInput
        source="description"
        toolbar={<RichTextInputToolbar><LevelSelect /><FormatButtons /></RichTextInputToolbar>} />
    </SimpleForm>
  )
}

// Edit
// ====
export const CompanyEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const CompanyCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
