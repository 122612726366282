import React, { useState } from 'react'
import keyBy from 'lodash/keyBy'
import TeamIcon from '@mui/icons-material/Group'
import { useFormContext } from 'react-hook-form'
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import { Button, useGetList, useNotify, Loading } from 'react-admin'

const ImportTeam = ({ identity, record }) => {
  const [teamId, setTeamId] = useState("")
  const { setValue } = useFormContext()
  const notify = useNotify()

  const { isLoading: teamsLoading, data: teams } = useGetList(
    'teams',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: { user_id: identity.id }
    }
  )

  if (teamsLoading) {
    return <Loading />
  }

  const importTeam = () => {
    if (!teamId) {
      return
    }

    const currentMembers = record ? record.members : []
    const currentIds = currentMembers.map((member) => member.user_id)
    const team = keyBy(teams, 'id')[teamId]

    const members = team.members
      .filter((member) => !currentIds.includes(member.user_id))
      .map((member) => ({ ...member, highlight: false, }))

    const newMembers = [...currentMembers, ...members]

    setValue('members', newMembers)
    notify('Team imported', { type: 'success' })
  }

  return (
    <Box mb="1em" display="flex" justifyContent="flex-start" alignItems="center">
      <FormControl sx={{ minWidth: '160px' }}>
        <InputLabel id="import-team">Choose a team</InputLabel>
        <Select labelId="import-team" onChange={(e) => setTeamId(e.target.value)} label="Choose a team" value={teamId}>
          {teams.filter((t) => t.user_id === identity.id).map((t) => (
            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button sx={{ marginLeft: '1em' }} variant="outlined" onClick={importTeam} label="Import team" startIcon={<TeamIcon />} color="secondary" size="large" />
    </Box>
  )
}

export default ImportTeam
