import { API_URL } from './utils'

// Auth Provider
// =============
export default {
  login: async ({ username, password }) => {
    if (username === '' || password === '') {
      return Promise.reject()
    }

    try {
      const res = await fetch(`${API_URL}/users`)

      if (!res.ok) {
        return Promise.reject('Unable to authenticate, please try again')
      }

      const data = await res.json()
      // FIXME: naive temporary version
      const user = data.find((user) => {
        return user.email === username
      })

      if (!user) {
        return Promise.reject('Incorrect username and password combination')
      }

      const cacheData = {
        id: user.id,
        fullName: `${user.first_name} ${user.last_name}`,
        permissions: user.permissions,
      }

      localStorage.setItem('_auth', JSON.stringify(cacheData))

      return Promise.resolve(cacheData)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  checkError: (error) => {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('_auth')
      return Promise.reject({ redirectTo: '/login' })
    }

    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem('_auth')
      ? Promise.resolve()
      : Promise.reject()
  },
  logout: () => {
    localStorage.removeItem('_auth')
    return Promise.resolve()
  },
  getIdentity: () => {
    try {
      const user = JSON.parse(localStorage.getItem('_auth'))
      return Promise.resolve(user)
    } catch (err) {
      return Promise.reject()
    }
  },
  getPermissions: () => {
    try {
      const { permissions } = JSON.parse(localStorage.getItem('_auth'))
      return Promise.resolve(permissions)
    } catch (err) {
      return Promise.reject()
    }
  },
}
