import * as React from 'react'
import { CallsheetDefaults } from './defaults'
import { scheduleTypes, scheduleInputStyles, weatherTypes } from './utils'
import { Typography, Box } from '@mui/material'
import { RichTextInput, RichTextInputToolbar, LevelSelect, FormatButtons } from 'ra-input-rich-text'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {
  ArrayInput,
  Button,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'

// NOTE: Can we set background colors of schedule events (from type)?

// List
// ====
export const CallsheetList = () => {
  return (
    <List sort={{ field: 'date', order: 'DESC' }} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />

        <ReferenceField source="project_id" reference="projects">
          <TextField source="name" />
        </ReferenceField>

        <DateField source="date" />
        <TextField source="call_time" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { permissions } = usePermissions()
  const record = useRecordContext()
  const isAdmin = permissions && permissions.includes('superuser')

  const toolbar = (!record || !record.project_id) ? undefined : (
    <Toolbar>
      <Box flex={1}>
        <SaveButton />
        <Button
          color="info"
          sx={{ marginLeft: '1em' }}
          label="Back to Project"
          startIcon={<ArrowBack />}
          href={`#/projects/${record.project_id}/3`} />
      </Box>
      <Box>
        <DeleteButton />
      </Box>
    </Toolbar>
  )

  return (
    <TabbedForm toolbar={toolbar} warnWhenUnsavedChanges defaultValues={CallsheetDefaults}>
      {/* GENERAL */}
      <FormTab label="General">
        <Typography variant="h6" mt="0.5em" gutterBottom>
          Callsheet
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          General callsheet information
        </Typography>

        <TextInput source="name" validate={required()} />

        <ReferenceInput source="project_id" reference="projects">
          <SelectInput emptyText="No project" validate={required()} disabled={!isAdmin} optionText="name" />
        </ReferenceInput>

        <DateInput validate={required()} source="date" />
        <TextInput validate={required()} source="call_time" />

        <NumberInput source="weather_degrees" />
        <SelectInput choices={weatherTypes} source="weather_type" emptyText="No weather type" optionText="name" />

        <RichTextInput
          source="notes"
          label="Notes (optional)"
          toolbar={<RichTextInputToolbar><LevelSelect /><FormatButtons /></RichTextInputToolbar>} />
      </FormTab>

      {/* MEMBERS */}
      <FormTab label="Members">
        <Typography variant="h6" mt="0.5em" gutterBottom>
          Members
        </Typography>

        <Typography variant="body2" mt="0.5em" gutterBottom>
          Manage what members are a part of the crew for this callsheet
        </Typography>

        <ArrayInput source="members">
          <SimpleFormIterator>
            <ReferenceInput label="Member" source="user_id" reference="users">
              <SelectInput defaultValue={null} emptyText="No member" helperText="" optionText={(r) => `${r.first_name} ${r.last_name}`} />
            </ReferenceInput>

            <ReferenceInput source="role_id" reference="roles" >
              <SelectInput defaultValue={null} emptyText="No role" helperText="" optionText="name" />
            </ReferenceInput>

            <TextInput validate={required()} label="Call time" defaultValue="08:00" helperText="" source="call_time" />
          </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" mt="0.5em" gutterBottom>
          Custom members
        </Typography>

        <Typography variant="body2" mt="0.5em" gutterBottom>
          Add custom members to this callsheet
        </Typography>

        <ArrayInput source="custom_members">
          <SimpleFormIterator>
            <TextInput defaultValue="" validate={required()} helperText="" source="name" />

            <ReferenceInput label="Role" source="role_id" reference="roles" >
              <SelectInput emptyText="No role" defaultValue={null} helperText="" optionText="name" />
            </ReferenceInput>

            <TextInput validate={required()} label="Call time" defaultValue="08:00" helperText="" source="call_time" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      {/* SCHEDULE */}
      <FormTab label="Schedule">
        <Typography variant="h6" mt="0.5em" gutterBottom>
          Schedule Events
        </Typography>

        <Typography variant="body2" >
          The order of these events are the same as displayed within a schedule
        </Typography>

        <ArrayInput sx={scheduleInputStyles} source="schedule">
          <SimpleFormIterator>
            <SelectInput emptyText="No type" sx={{ minWidth: 'auto', maxWidth: '120px' }} defaultValue={scheduleTypes[0].id} helperText="" source="type" choices={scheduleTypes} />
            <NumberInput sx={{ minWidth: 'auto', maxWidth: '120px' }} defaultValue={20} helperText="" label="Duration (min)" source="duration" step={5} />
            <TextInput defaultValue="" label="Name (optional)" helperText="" source="name" />

            <ReferenceInput filter={{ project_id: record && record.project_id }} label="Scene (optional)" source="scene_id" reference="scenes">
              <SelectInput defaultValue={null} emptyText="No scene" helperText="" optionText="name" />
            </ReferenceInput>

            <ReferenceInput emptyText="No location" label="Location (optional)" source="location_id" reference="locations">
              <SelectInput emptyText="No location" defaultValue={null} helperText="" optionText={(r) => `${r.name} (${r.adress})`} />
            </ReferenceInput>

            <TextInput defaultValue="" multiline helperText="" label="Notes (optional)" source="notes" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab >
    </TabbedForm >
  )
}

// Edit
// ====
export const CallsheetEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const CallsheetCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
