import * as React from 'react'
import { ProjectDefaults } from './defaults'
import { projectStatuses, memberHighlight, } from './utils'
import { useGetIdentity, usePermissions, useRecordContext } from 'react-admin'
import { RichTextInput, RichTextInputToolbar, LevelSelect, FormatButtons } from 'ra-input-rich-text'
import { Typography, Box } from '@mui/material'
import { ImportTeam } from './Components'
import {
  ArrayInput,
  ChipField,
  CloneButton,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  Loading,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'

// List
// ====
export const ProjectList = () => {
  const { identity, isLoading: identityLoading } = useGetIdentity()
  const { permissions, isLoading: permissionsLoading } = usePermissions()

  if (identityLoading || permissionsLoading) {
    return <Loading />
  }

  const filter = permissions && permissions.includes('superuser')
    ? null
    : { user_id: identity.id }

  return (
    <List filter={filter} filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <ChipField source="status" />
        <TextField source="client" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { identity, isLoading } = useGetIdentity()
  const record = useRecordContext()

  if (isLoading) {
    return <Loading />
  }

  return (
    <TabbedForm warnWhenUnsavedChanges defaultValues={ProjectDefaults}>
      {/* GENERAL */}
      <FormTab label="General">
        <Typography variant="h6" mt="0.5em">
          Project
        </Typography>

        <Typography variant="subtitle2" gutterBottom>
          General information about the project
        </Typography>

        <TextInput defaultValue={identity.id} disabled source="user_id" sx={{ display: 'none' }} />
        <TextInput label="Project name" source="name" validate={required()} />
        <SelectInput emptyText="No status" choices={projectStatuses} source="status" validate={required()} />
        <TextInput source="production_company" validate={required()} />
        <TextInput source="client" validate={required()} />
        <DateInput validate={required()} source="start_date" />
        <DateInput validate={required()} source="end_date" />

        <RichTextInput
          source="notes"
          label="Notes (optional)"
          toolbar={<RichTextInputToolbar><LevelSelect /><FormatButtons /></RichTextInputToolbar>} />

        <Typography variant="h6" mt="0.5em">
          Additional admins
        </Typography>

        <Typography variant="subtitle2" gutterBottom>
          Members that are able to administrate this project
        </Typography>

        <Box>
          <ArrayInput source="admin_ids">
            <SimpleFormIterator>
              <ReferenceInput reference="users">
                <SelectInput
                  defaultValue={null}
                  emptyText="No member"
                  label="Member"
                  sx={{ marginRight: '0.5em' }}
                  helperText=""
                  optionText={(r) => `${r.first_name} ${r.last_name}`} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </FormTab>

      {/* MEMBERS */}
      <FormTab label="Members">
        <Box>
          <ImportTeam identity={identity} record={record} />

          <Typography variant="h6" mt="0.5em">
            Members
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            Manage what members are a part of this project
          </Typography>

          <ArrayInput source="members">
            <SimpleFormIterator>
              <ReferenceInput reference="users" source="user_id">
                <SelectInput
                  defaultValue={null}
                  emptyText="No user"
                  helperText=""
                  label="Member"
                  optionText={(r) => `${r.first_name} ${r.last_name}`} />
              </ReferenceInput>

              <ReferenceInput reference="roles" source="role_id">
                <SelectInput emptyText="No role" defaultValue={null} helperText="" optionText="name" />
              </ReferenceInput>

              <SelectInput
                choices={memberHighlight}
                defaultValue={memberHighlight[0].id}
                helperText=""
                label="Visibility"
                source="highlight" />
            </SimpleFormIterator>
          </ArrayInput>

          <Typography variant="h6" mt="2em">
            Custom members
          </Typography>

          <Typography variant="subtitle2" gutterBottom>
            Add custom members to the project
          </Typography>

          <ArrayInput source="custom_members">
            <SimpleFormIterator>
              <TextInput helperText="" source="name" validate={required()} />

              <ReferenceInput reference="roles" source="role_id">
                <SelectInput defaultValue={null} emptyText="No role" helperText="" optionText="name" />
              </ReferenceInput>

              <TextInput defaultValue="" helperText="" source="email" validate={required()} />
              <TextInput defaultValue="" helperText="" source="phone" validate={required()} />

              <SelectInput
                choices={memberHighlight}
                defaultValue={memberHighlight[0].id}
                helperText=""
                label="Visibility"
                source="highlight" />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </FormTab>

      {/* SCENES */}
      <FormTab label="Scenes">
        {(!record || !record.id) && (
          <Typography variant="subtitle2" gutterBottom>
            You need to save your project before adding scenes
          </Typography>
        )}

        {(record && record.id !== undefined) && (
          <Box sx={{ minWidth: '800px' }}>
            <ReferenceManyField reference="scenes" sort={{ field: 'name', order: 'ASC' }} target="project_id">
              <Datagrid rowClick="edit">
                <TextField source="name" />
                <ReferenceField source="location_id" reference="locations">
                  <TextField source="name" />
                </ReferenceField>

                <EditButton />
                <CloneButton />
                <DeleteButton mutationMode="pessimistic" />
              </Datagrid>
            </ReferenceManyField>

            <CreateButton
              label="Add scene"
              to={{
                pathname: '/scenes/create',
                state: {
                  record: {
                    project_id: record.id,
                    /* FIXME: Temporary! */
                    actors: record.members.filter((member) => member.role_id === 7),
                    custom_actors: record.custom_members.filter((member) => member.role_id === 7),
                  }
                },
              }} />
          </Box>
        )}
      </FormTab>

      {/* CALLSHEETS */}
      <FormTab label="Callsheets">
        {(!record || !record.id) && (
          <Typography variant="subtitle2" gutterBottom>
            You need to save your project before adding callsheets
          </Typography>
        )}

        {(record && record.id !== undefined) && (
          <Box sx={{ minWidth: '800px' }}>
            <ReferenceManyField reference="callsheets" sort={{ field: 'date', order: 'ASC' }} target="project_id">
              <Datagrid rowClick="edit">
                <TextField source="name" />
                <DateField source="date" />
                <TextField source="call_time" />

                <EditButton />
                <CloneButton />
                <DeleteButton mutationMode="pessimistic" />
              </Datagrid>
            </ReferenceManyField>

            <CreateButton
              label="Add callsheet"
              to={{
                pathname: '/callsheets/create',
                state: {
                  record: {
                    project_id: record.id,
                    members: record.members,
                    custom_members: record.custom_members,
                  }
                },
              }} />
          </Box>
        )}
      </FormTab>

      {/* DOCUMENTS */}
      <FormTab label="Documents">
        {(!record || !record.id) && (
          <Typography variant="subtitle2" gutterBottom>
            You need to save your project before adding documents
          </Typography>
        )}

        {(record && record.id !== undefined) && (
          <Box sx={{ minWidth: '800px' }}>
            <ReferenceManyField reference="documents" sort={{ field: 'created_at', order: 'ASC' }} target="project_id">
              <Datagrid rowClick="edit">
                <TextField source="name" />
                <TextField label="File" source="file.title" />
                <DateField label="Date" source="created_at" />

                <EditButton />
                <CloneButton />
                <DeleteButton mutationMode="pessimistic" />
              </Datagrid>
            </ReferenceManyField>

            <CreateButton
              label="Add document"
              to={{
                pathname: '/documents/create',
                state: {
                  record: {
                    project_id: record.id,
                  }
                },
              }} />
          </Box>
        )}
      </FormTab>
    </TabbedForm>
  )
}

// Edit
// ====
export const ProjectEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const ProjectCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
