import * as React from 'react'
import { SceneDefaults } from './defaults'
import { timeOfDay } from './utils'
import { Typography, Box } from '@mui/material'
import { RichTextInput, RichTextInputToolbar, LevelSelect, FormatButtons } from 'ra-input-rich-text'
import ArrowBack from '@mui/icons-material/ArrowBack'
import {
  ArrayInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useRecordContext,
} from 'react-admin'

// List
// ====
export const SceneList = () => {
  return (
    <List filters={[<TextInput label="Search" source="q" alwaysOn />]}>
      <Datagrid rowClick="edit">
        <TextField source="name" />

        <ReferenceField source="project_id" reference="projects">
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="location_id" reference="locations">
          <TextField source="name" />
        </ReferenceField>

        <ChipField source="time_of_day" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

// Form
// ====
const Form = () => {
  const { permissions } = usePermissions()
  const record = useRecordContext()
  const isAdmin = permissions && permissions.includes('superuser')

  const toolbar = (!record || !record.project_id) ? undefined : (
    <Toolbar>
      <Box flex={1}>
        <SaveButton />
        <Button
          color="info"
          sx={{ marginLeft: '1em' }}
          label="Back to Project"
          startIcon={<ArrowBack />}
          href={`#/projects/${record.project_id}/2`} />
      </Box>
      <Box>
        <DeleteButton />
      </Box>
    </Toolbar>
  )

  return (
    <TabbedForm toolbar={toolbar} warnWhenUnsavedChanges defaultValues={SceneDefaults}>
      {/* GENERAL */}
      <FormTab label="General">
        <Typography variant="h6" mt="0.5em" gutterBottom>
          Scene
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          General scene information
        </Typography>

        <TextInput source="name" validate={required()} />

        <ReferenceInput source="project_id" reference="projects">
          <SelectInput emptyText="No project" validate={required()} disabled={!isAdmin} optionText="name" />
        </ReferenceInput>

        <SelectInput validate={required()} source="time_of_day" choices={timeOfDay} />

        <ReferenceInput source="location_id" reference="locations">
          <SelectInput emptyText="No location" optionText="name" />
        </ReferenceInput>

        <RichTextInput
          source="description"
          toolbar={<RichTextInputToolbar><LevelSelect /><FormatButtons /></RichTextInputToolbar>} />
      </FormTab>

      {/* ACTORS */}
      <FormTab label="Actors">
        <Typography variant="h6" mt="0.5em" gutterBottom>
          Actors
        </Typography>

        <Typography variant="subtitle2">
          Manage what Actors are a part of this scene
        </Typography>

        <ArrayInput source="actors">
          <SimpleFormIterator>
            <ReferenceInput reference="users" source="user_id">
              <SelectInput defaultValue={null} emptyText="No user" helperText="" optionText={(r) => `${r.first_name} ${r.last_name}`} />
            </ReferenceInput>
            <TextInput defaultValue="" helperText="" source="description" />
          </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" mt="2em" gutterBottom>
          Custom actors
        </Typography>

        <Typography variant="subtitle2">
          Add custom actors to this scene
        </Typography>

        <ArrayInput source="custom_actors">
          <SimpleFormIterator>
            <TextInput defaultValue="" validate={required()} helperText="" source="name" />
            <TextInput defaultValue="" helperText="" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm >
  )
}

// Edit
// ====
export const SceneEdit = () => (
  <Edit redirect={false}>
    <Form />
  </Edit>
)

// Create
// ======
export const SceneCreate = () => (
  <Create redirect="edit">
    <Form />
  </Create>
)
